

















































































































import { Api, SubscriptionActionInfo, SubscriptionPlanInfo } from '@/edshed-common/api'
import ComponentHelper from '@/mixins/ComponentHelper'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import PriceTiers from './PriceTiers.vue'

@Component({
  name: 'AddPlanMigration',
  components: { PriceTiers }
})
export default class AddPlanMigration extends Mixins(ComponentHelper) {
  @Prop({ required: true }) planToMigrate!: SubscriptionPlanInfo

  migrationDate: Date = new Date()

  migrationOptions: SubscriptionPlanInfo[] = []

  planToMigrateTo: SubscriptionPlanInfo | null = null

  migrationQuantity: number | null = null

  baseMigrationId: string | null = null

  addOnMigrationMap: { from: SubscriptionPlanInfo, to: SubscriptionPlanInfo | null, keep: boolean }[] = []

  basePlanAllowOrphans: boolean = true

  step: number = 0

  @Watch('planToMigrateTo')
  migrateToChanged (val: SubscriptionPlanInfo) {
    if (val.metered_entity && this.planToMigrate && !this.planToMigrate.metered_entity) {
      this.migrationQuantity = val.min ?? 1
    } else {
      this.migrationQuantity = null
    }

    this.addOnMigrationMap = []

    for (const child of this.planToMigrate.children) {
      const match = val.children.find(c => c.add_on_id === child.add_on_id)
      this.addOnMigrationMap.push({ from: child, to: match ?? null, keep: true })
    }
  }

  @Watch('canSaveMigration')
  canSaveMigrationChanged (val: boolean) {
    this.$emit('can-save-migration', val)
  }

  mounted () {
    this.loadMigrationOptions()
  }

  get canSaveMigration () {
    return this.migrationDate && this.planToMigrateTo && this.step === 3
  }

  hasProductBeenDropped (original: SubscriptionPlanInfo) {
    const replacement = this.addOnMigrationMap.find(a => a.from.id === original.id)

    if (replacement === undefined || replacement.to === null) {
      return false
    }

    return original.bundle_parents.length > replacement.to.bundle_parents.length ||
    original.bundle_parents.some(p => !replacement.to!.bundle_parents.find(r => r.product_id === p.product_id))
  }

  isAddOnNotMigrating (addOn: SubscriptionPlanInfo) {
    return this.addOnMigrationMap.find(a => a.from.id === addOn.id)?.to === null
  }

  migrationSelected (addOn: SubscriptionPlanInfo, to: SubscriptionPlanInfo | null) {
    const existing = this.addOnMigrationMap.find(a => a.from.id === addOn.id)

    if (existing) {
      existing.to = to
    } else {
      this.addOnMigrationMap.push({ from: addOn, to, keep: true })
    }
  }

  keepBehaviourSet (addOn: SubscriptionPlanInfo, keep: boolean) {
    const entry = this.addOnMigrationMap.find(a => a.from.id === addOn.id)

    if (entry) {
      entry.keep = keep
    }
  }

  async loadMigrationOptions () {
    const plan = this.planToMigrate
    try {
      const plans = await Api.getSubscriptionPlans({ scope_id: plan.scope.id, product_id: plan.product.id, currency: plan.currency, domestic: plan.domestic, cycle: plan.cycle, parent_id: plan.parent_id === null ? null : undefined }, undefined)

      this.migrationOptions = plans.filter(p => p.id !== plan.id && (plan.parent_id ? p.parent_id : p.parent_id === null))
    } catch (err: unknown) {
      this.$buefy.toast.open({
        message: 'Could not load migration options',
        type: 'is-danger',
        position: 'is-bottom'
      })

      if (err instanceof Error) {
        console.log(err.message)
      }
    }
  }

  async saveMigration () {
    try {
      if (!this.canSaveMigration) {
        throw new Error('Can\'t save')
      }

      if (!this.planToMigrate) {
        throw new Error('Plan required')
      }

      if (!this.planToMigrateTo) {
        throw new Error('Plan required')
      }

      const addedMigrations: SubscriptionActionInfo[] = []

      const baseAction = await Api.addActionForPlan(this.planToMigrate.id, {
        action: 'migrate_renewal',
        date: this.migrationDate,
        to_id: this.planToMigrateTo.id,
        migration_quantity: this.migrationQuantity,
        batch_id: null,
        // behaviour: this.basePlanAllowOrphans ? 'keep' : 'remove'
        behaviour: 'keep'
      })

      addedMigrations.push(baseAction)

      for (const addOn of this.addOnMigrationMap) {
        console.log({ addOn })
        const addOnAction = await Api.addActionForPlan(addOn.from.id, {
          action: 'migrate_renewal',
          date: this.migrationDate,
          to_id: addOn.to === null ? null : addOn.to.id,
          migration_quantity: this.migrationQuantity,
          batch_id: baseAction.db_data!.batch_id,
          behaviour: addOn.keep ? 'keep' : 'remove'
        })

        addedMigrations.push(addOnAction)
      }

      for (const action of addedMigrations) {
        this.$emit('migration-added', action)
      }
    } catch (err: unknown) {
      this.$buefy.toast.open({
        message: 'Could not save data',
        position: 'is-bottom',
        type: 'is-danger'
      })
    }
  }
}
