



































































import { SubscriptionPlanPriceTier, SubscriptionPlanPriceType } from '@/edshed-common/api'
import { Currency } from '@/edshed-common/api/types/currency'
import ComponentHelper from '@/mixins/ComponentHelper'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import CurrencyInput from './CurrencyInput.vue'

@Component({
  name: 'SubscriptionPriceTiers',
  components: { CurrencyInput }
})
export default class SubscriptionPriceTiers extends Mixins(ComponentHelper) {
  @Prop({ default: () => [] }) value!: SubscriptionPlanPriceType[]

  @Prop({ required: true }) priceType!: SubscriptionPlanPriceType[]

  @Prop({ required: true }) currency!: Currency

  @Prop({ default: false }) disabled!: boolean

  @Watch('value', { immediate: true })
  valueChanged (val: SubscriptionPlanPriceTier[]) {
    this.priceTiers = val
  }

  @Watch('priceTiers', { immediate: true })
  priceTiersChanged (val: SubscriptionPlanPriceTier[]) {
    this.$emit('input', val)
  }

  priceTiers: SubscriptionPlanPriceTier[] = []

  addTier () {
    const previousTier = this.priceTiers[this.priceTiers.length - 1] as SubscriptionPlanPriceTier | undefined

    if (previousTier?.up_to === null) {
      const hasAnotherTier = this.priceTiers.length >= 2

      if (hasAnotherTier) {
        const previousPreviousTier = this.priceTiers[this.priceTiers.length - 2]
        previousTier.up_to = previousPreviousTier.up_to! + 2
      } else {
        previousTier.up_to = 1
      }
    }

    this.priceTiers.push({
      up_to: null,
      flat_amount: '0.00',
      unit_amount: '0.00'
    })
  }

  removeTier (index: number) {
    this.priceTiers.splice(index, 1)
  }
}
