































import { Api, SchoolOrgType, SubscriptionProductScopeInfo } from '@/edshed-common/api'
import ComponentHelperBase from '@/edshed-common/mixins/ComponentHelperBase'
import { Component, Mixins, Prop } from 'vue-property-decorator'

@Component({
  name: 'AddSubscriptionScope'
})
export default class AddSubscriptionScope extends Mixins(ComponentHelperBase) {
  @Prop({ default: null })
  editScope!: SubscriptionProductScopeInfo | null

  @Prop({ required: true })
  productId!: number

  SchoolOrgType = SchoolOrgType

  title: string = ''

  orgTypes: SchoolOrgType[] = []

  async saveScope () {
    try {
      if (this.title.trim() === '') {
        this.$buefy.toast.open({
          message: 'Title required',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }

      if (this.orgTypes.length < 1) {
        this.$buefy.toast.open({
          message: 'At least one org type required',
          position: 'is-bottom',
          type: 'is-danger'
        })
        return
      }

      if (this.editScope) {
        const scope = await Api.updateSubscriptionProductScope(this.editScope.id, {
          title: this.title
        })

        this.$emit('updated', scope)
      } else {
        const scope = await Api.addSubscriptionProductScope(this.productId, {
          title: this.title,
          org_types: this.orgTypes
        })

        this.$emit('created', scope)
      }
    } catch (err) {
      console.log(err)

      this.$buefy.toast.open({
        message: 'Could not save data',
        position: 'is-bottom',
        type: 'is-danger'
      })
    }
  }
}
