































































































































































































































































import { Api, SubscriptionPlanCycleType, SubscriptionPlanInfo, SubscriptionProductInfo, SubscriptionProductScopeInfo } from '@/edshed-common/api'
import Component from 'vue-class-component'
import ComponentHelper from '@/mixins/ComponentHelper'
import { Mixins, Watch } from 'vue-property-decorator'
import { Currency, DomesticCurrency } from '@/edshed-common/api/types/currency'
import AddSubscriptionScope from './views/components/AddSubscriptionScope.vue'
import AddSubscriptionPlan from './views/components/AddSubscriptionPlan.vue'

@Component({
  name: 'SubscriptionProduct',
  components: { AddSubscriptionScope, AddSubscriptionPlan }
})
export default class SubscriptionProduct extends Mixins(ComponentHelper) {
  loading: boolean = false
  isAddingScope: boolean = false
  addingPlan: { scope: SubscriptionProductScopeInfo, domestic: boolean, currency: Currency, cycle: SubscriptionPlanCycleType } | null = null
  addingVersionForPlan: SubscriptionPlanInfo | null = null
  editingPlan: SubscriptionPlanInfo | null = null

  product: SubscriptionProductInfo | null = null

  scopeToEdit: SubscriptionProductScopeInfo | null = null

  scopes: SubscriptionProductScopeInfo[] = []

  plans: SubscriptionPlanInfo[] = []

  cycle: SubscriptionPlanCycleType = 'yearly'

  DomesticCurrencies = DomesticCurrency
  IntlCurrencies = Currency

  needsSave: boolean = false

  willActivate: false | SubscriptionPlanInfo = false

  @Watch('cycle')
  async cycleChanged () {
    const id = parseInt(this.$route.params.id)

    this.plans = await Api.getSubscriptionPlans({ product_id: id, parent_id: null, cycle: this.cycle }, undefined)
  }

  async mounted () {
    const id = parseInt(this.$route.params.id)
    this.product = await Api.getSubscriptionProduct(id)
    const scopes = await Api.getSubscriptionProductScopes(id, { take: 200 })
    this.scopes = scopes.items

    this.plans = await Api.getSubscriptionPlans({ product_id: id, parent_id: null, cycle: this.cycle }, undefined)

    this.$nextTick(() => {
      const domesticHeader = document.getElementsByClassName('domestic-header').item(0)
      const internationalHeader = document.getElementsByClassName('international-header').item(0)

      if (domesticHeader) {
        domesticHeader.setAttribute('colspan', this.DomesticCurrencies.length.toString())
      }

      if (internationalHeader) {
        internationalHeader.setAttribute('colspan', this.IntlCurrencies.length.toString())
      }
    })
  }

  closeAddModal () {
    this.isAddingScope = false
  }

  closeAddPlanModal () {
    this.addingPlan = null
  }

  closeEditPlanModal () {
    this.editingPlan = null
  }

  startAddingScope () {
    this.isAddingScope = true
    this.scopeToEdit = null
  }

  startAddingPlan (scope: SubscriptionProductScopeInfo, domestic: boolean, currency: Currency, cycle: SubscriptionPlanCycleType) {
    this.addingPlan = {
      scope,
      domestic,
      currency,
      cycle
    }
  }

  startEditingPlan (plan: SubscriptionPlanInfo) {
    this.editingPlan = plan
  }

  saveScope () {
    (this.$refs.addScope as AddSubscriptionScope).saveScope()
  }

  scopeCreated (scope: SubscriptionProductScopeInfo) {
    this.scopes.push(scope)

    this.closeAddModal()
  }

  scopeUpdated (scope: SubscriptionProductScopeInfo) {
    const existingScope = this.scopes.find(s => s.id === scope.id)

    if (existingScope) {
      Object.assign(existingScope, scope)
    }

    this.closeAddModal()
  }

  savePlan () {
    (this.$refs.addPlan as AddSubscriptionPlan).savePlan()
  }

  updatePlan () {
    (this.$refs.editPlan as AddSubscriptionPlan).savePlan()
  }

  planCreated (plan: SubscriptionPlanInfo) {
    this.plans.push(plan)

    this.closeAddPlanModal()
  }

  planUpdated (plan: SubscriptionPlanInfo) {
    if (plan.parent_id === null) {
      const existingPlan = this.plans.find(p => p.id === plan.id)

      if (existingPlan) {
        Object.assign(existingPlan, plan)
      }
    } else {
      const existingParent = this.plans.find(p => p.id === plan.parent_id)

      if (existingParent) {
        const existingPlan = existingParent.children.find(p => p.id === plan.id)

        if (existingPlan) {
          Object.assign(existingPlan, plan)
        }
      }
    }

    this.closeEditPlanModal()
  }

  get isSuperDuperUser () {
    return this.$store.state.user.superduperuser
  }

  findPlan (scope: SubscriptionProductScopeInfo, domestic: boolean, currency: Currency, active: boolean) {
    return this.plans.find(p => p.active === active && p.scope_id === scope.id && p.domestic === domestic && p.parent_id === null && p.base && p.currency === currency)
  }

  createNew (event: { scope: SubscriptionProductScopeInfo, domestic: boolean, currency: Currency, cycle: SubscriptionPlanCycleType, plan: SubscriptionPlanInfo }) {
    this.editingPlan = null
    this.addingVersionForPlan = event.plan
    this.startAddingPlan(event.scope, event.domestic, event.currency, event.cycle)
  }
}

