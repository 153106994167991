














































































































































































































































































































































































































































































































































































































































import { Api, SubscriptionPlanCycleType, SubscriptionPlanInfo, SubscriptionPlanPriceTier, SubscriptionPlanPriceType, SubscriptionProductInfo, SubscriptionProductScopeInfo, SubscriptionActionInfo, SubscriptionPlanBundleStub, Subscription, PagedResults, Now, UserPermissionKeys, SubscriptionPermissionRule, SchoolPermissionKeys, TableState } from '@/edshed-common/api'
import { Currency } from '@/edshed-common/api/types/currency'
import { SubscriptionMeteredEntity } from '@/edshed-common/subscriptionPackages'
import ComponentHelper from '@/mixins/ComponentHelper'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import moment from 'moment'
import _ from 'lodash'
import CurrencyInput from './CurrencyInput.vue'
import SubscriptionPricingTiers from './SubscriptionPricingTiers.vue'
import AddPlanMigration from './AddPlanMigration.vue'
import Timeline from './Timeline.vue'

interface AddOnDetails {
  id: number | null,
  price_type: SubscriptionPlanPriceType,
  price_tiers: SubscriptionPlanPriceTier[],
  unit_amount: string,
  bundles_with: SubscriptionPlanBundleStub[],
  add_on_id: string | null,
}

@Component({
  name: 'AddSubscriptionPlan',
  components: { CurrencyInput, SubscriptionPricingTiers, Timeline, AddPlanMigration }
})
export default class AddSubscriptionPlan extends Mixins(ComponentHelper) {
  @Prop({ default: null })
  editPlanProp!: SubscriptionPlanInfo | null

  @Prop({ required: false, default: true })
  domesticProp!: boolean

  @Prop({ required: false, default: 'gbp' })
  currencyProp!: Currency

  @Prop({ required: false, default: 'yearly' })
  cycleProp!: SubscriptionPlanCycleType

  @Prop({ required: true })
  product!: SubscriptionProductInfo

  @Prop({ required: true })
  scope!: SubscriptionProductScopeInfo

  @Prop({ required: false, default: null })
  versionOfPlan!: SubscriptionPlanInfo | null

  @Watch('classesLicensed')
  classLicensingChanged (val: boolean) {
    if (!val && this.meteredEntity === 'class') {
      // look for another licensed type
      if (this.teachersLicensed) {
        this.meteredEntity = 'teacher'
      } else if (this.pupilsLicensed) {
        this.meteredEntity = 'pupil'
      } else {
        this.meteredEntity = null
      }
    }

    if (!val) {
      this.classUnitQuantity = null
    } else {
      this.classUnitQuantity = this.editPlan?.classes ?? 1
    }
  }

  @Watch('teachersLicensed')
  teacherLicensingChanged (val: boolean) {
    if (!val && this.meteredEntity === 'teacher') {
      // look for another licensed type
      if (this.classesLicensed) {
        this.meteredEntity = 'class'
      } else if (this.pupilsLicensed) {
        this.meteredEntity = 'pupil'
      } else {
        this.meteredEntity = null
      }
    }

    if (!val) {
      this.teacherUnitQuantity = null
    } else {
      this.teacherUnitQuantity = this.editPlan?.teachers ?? 1
    }
  }

  @Watch('pupilsLicensed')
  pupilLicensingChanged (val: boolean) {
    if (!val && this.meteredEntity === 'pupil') {
      // look for another licensed type
      if (this.teachersLicensed) {
        this.meteredEntity = 'teacher'
      } else if (this.classesLicensed) {
        this.meteredEntity = 'class'
      } else {
        this.meteredEntity = null
      }
    }

    if (!val) {
      this.pupilUnitQuantity = null
    } else {
      this.pupilUnitQuantity = this.editPlan?.pupils ?? 1
    }
  }

  @Watch('meteredEntity')
  meteredEntityChanged (val: SubscriptionMeteredEntity) {
    if (val === 'pupil') {
      this.pupilUnitQuantity = 1
    } else if (val === 'teacher') {
      this.teacherUnitQuantity = 1
    } else if (this.classUnitQuantity) {
      this.classUnitQuantity = 1
    }
  }

  @Watch('skuGuess')
  guessedSkuChanged (val: string) {
    if (!this.editPlan) {
      this.sku = val
    } else if (this.versionOfPlan) {
      this.sku = val
    }
  }

  @Watch('versionOfPlan', { immediate: true })
  versionOfPlanChanged () {
    this.initialiseForPlan()
  }

  @Watch('editPlanProp', { immediate: true })
  editPlanChanged () {
    this.editPlan = _.cloneDeep(this.editPlanProp)
    this.initialiseForPlan()
    this.currentTab = 0
  }

  @Watch('needsSave', { immediate: true })
  needsSaveChanged (val: boolean) {
    this.$emit('needs-save', val)
  }

  @Watch('willActivate', { immediate: true })
  willActivateChanged (val: false | SubscriptionActionInfo) {
    this.$emit('will-activate', val)
  }

  SubscriptionPlanPriceType = SubscriptionPlanPriceType

  SubscriptionMeteredEntity = SubscriptionMeteredEntity

  currentTab: number = 0 // for top menu

  selectedTab: number = 0 // for add-ons menu

  sku: string = ''

  priceType: SubscriptionPlanPriceType = 'fixed'

  unitAmount: string = '0.00'

  meteredEntity: SubscriptionMeteredEntity | null = null

  min: number | null = null

  max: number | null = null

  pupilsLicensed = false

  teachersLicensed = false

  classesLicensed = false

  pupilUnitQuantity: number | null = null

  teacherUnitQuantity: number | null = null

  classUnitQuantity: number | null = null

  priceTiers: SubscriptionPlanPriceTier[] = [{
    up_to: null,
    flat_amount: '0.00',
    unit_amount: '0.00'
  }]

  addOnPricesOriginal: AddOnDetails[] = []

  addOnPrices: AddOnDetails[] = []

  bundleablePlans: SubscriptionPlanInfo[] = []

  bundleFilter: string = ''

  activationDate: Date | null = null

  deprecationDate: Date | null = null

  activationType: 'immediate' | 'later' | 'legacy' = 'immediate'

  deprecationType: 'immediate' | 'later' = 'immediate'

  scheduledActions: SubscriptionActionInfo[] = []

  alternatePlans: SubscriptionPlanInfo[] = []

  editPlan: SubscriptionPlanInfo | null = null

  subscriptions: PagedResults<Subscription> = {
    items: [],
    total: 0
  }

  subscriptionTable: TableState = {
    page: 1,
    dir: 'asc',
    sort: 'expiry',
    perPage: 10,
    term: ''
  }

  pupilPermissions: SubscriptionPermissionRule<UserPermissionKeys>[] = []

  teacherPermissions: SubscriptionPermissionRule<UserPermissionKeys>[] = []

  classPermissions: SubscriptionPermissionRule<UserPermissionKeys>[] = []

  schoolPermissions: SubscriptionPermissionRule<SchoolPermissionKeys>[] = []

  planToMigrate: SubscriptionPlanInfo | null = null

  planToDeprecate: SubscriptionPlanInfo | null = null

  planToActivate: SubscriptionPlanInfo | null = null

  canSaveMigration: boolean = false

  savingPlans: boolean = false

  get currentAddOn () {
    if (this.selectedTab === 0) {
      return null
    }

    return this.addOnPrices[this.selectedTab - 1]
  }

  get filteredBundlePlans (): SubscriptionPlanBundleStub[] {
    const otherAddons = this.addOnPrices.filter((p, i) => (i + 1) !== this.selectedTab)

    // matches on chosen cycle, and at least some overlap in scope
    const plans: SubscriptionPlanInfo[] = this.bundleablePlans.filter((p) => {
      if (p.id === this.editPlan?.id) {
        return false
      }

      if (p.product_id === this.product.id) {
        return false
      }

      if (!`${p.product.title} - ${p.scope.title}`.includes(this.bundleFilter)) {
        return false
      }

      if (p.cycle !== this.cycleProp) {
        return false
      }

      if (!p.scope.org_types.some(s => this.scope.org_types.includes(s))) {
        return false
      }

      if (p.metered_entity !== this.meteredEntity) {
        return false
      }

      if (otherAddons.some(a => a.bundles_with.find(b => b.scope_id === p.scope_id))) {
        return false
      }

      if (this.currentAddOn && this.currentAddOn.bundles_with.find(b => b.scope_id === p.scope_id)) {
        return false
      }

      return true
    })

    return _.uniqBy(plans, 'scope_id').map(p => ({
      id: p.id,
      product_name: p.product.title,
      scope_name: p.scope.title,
      scope_id: p.scope_id,
      product_id: p.product_id,
      display: `${p.product.title} - ${p.scope.title}`
    }))
  }

  get skuGuess () {
    const versionCount = this.alternatePlans.length
    const versionPostFix = versionCount ? `-v${versionCount + 1}` : ''

    if (this.versionOfPlan) {
      const trimVersionPostfix = this.versionOfPlan.sku.replace(/-v\d+$/, '')
      return `${trimVersionPostfix}${versionPostFix}`
    }

    return `${this.product.title.replace(' ', '-').toLowerCase()}-${this.scope.title.replace(' ', '-').toLowerCase()}-${this.cycleProp}-${this.domesticProp ? 'dom' : 'intl'}-${this.currencyProp}${versionPostFix}`
  }

  get willActivate () {
    if (this.editPlan === null) {
      return false
    }

    if (this.editPlan.active) {
      return false
    }

    const futureActions = this.scheduledActions.filter(a => moment(a.date).isAfter(moment()))

    return futureActions.find(a => (a.db_data?.type ?? a.queue_data?.action?.action ?? '') === 'activate') ?? false
  }

  get canChangeMeteredEntity () {
    if (!this.editPlan) {
      return true
    }

    return this.addOnPrices.every(p => p.bundles_with.length === 0) &&
      this.editPlan.bundle_children.length === 0
  }

  get reconstructedObject (): SubscriptionPlanInfo {
    return {
      id: this.editPlanProp?.id || 0,
      parent_id: null,
      sku: this.sku,
      price_type: this.priceType,
      price_tiers: this.priceType !== 'fixed' ? this.priceTiers : null,
      unit_amount: this.priceType === 'fixed' ? this.unitAmount : null,
      currency: this.currencyProp,
      product_id: this.product.id,
      product: this.product,
      account_region: (this.currencyProp === 'usd' && this.domesticProp === false) ? 'US' : 'GB',
      stripe_id: this.editPlanProp?.stripe_id ?? '',
      scope_id: this.scope.id,
      scope: this.scope,
      cycle: this.cycleProp,
      domestic: this.domesticProp,
      base: true,
      active: this.editPlanProp?.active ?? false,
      created: this.editPlanProp?.created ?? Now(),
      children: this.editPlanProp?.children ?? [],
      add_on_id: null,
      pupils: this.pupilUnitQuantity,
      teachers: this.teacherUnitQuantity,
      classes: this.classUnitQuantity,
      min: this.min,
      max: this.max,
      metered_entity: this.meteredEntity,
      bundle_children: this.editPlanProp?.bundle_children ?? [],
      bundle_parents: this.editPlanProp?.bundle_parents ?? [],
      permissions: {
        pupil_permissions: this.pupilPermissions,
        teacher_permissions: this.teacherPermissions,
        class_permissions: this.classPermissions,
        school_permissions: this.schoolPermissions
      }
    }
  }

  get hasMigration () {
    return this.scheduledActions.find(a => a.db_data?.type === 'migrate_renewal' && (a.db_data!.plan_id === this.editPlan?.id || this.editPlan?.children.some(add => add.id === a.db_data!.plan_id))) !== undefined
  }

  get needsSave () {
    if (this.editPlan) {
      return !_.isEqual(this.editPlanProp, this.reconstructedObject) || !_.isEqual(this.addOnPricesOriginal, this.addOnPrices)
    }

    return true
  }

  get canSaveActivation () {
    if (this.activationType === 'immediate') {
      return true
    } else if (this.activationType === 'later' && this.activationDate !== null) {
      return true
    }

    return false
  }

  get canSaveDeprecation () {
    if (this.deprecationType === 'immediate') {
      return true
    } else if (this.deprecationType === 'later' && this.deprecationDate !== null) {
      return true
    }

    return false
  }

  mounted () {
  }

  subTablePageChanged (page: number) {
    this.subscriptionTable.page = page
    this.loadSubscriptions()
  }

  initialiseForPlan () {
    this.loadBundleableComponents()
    this.loadAlternatePlans()
    this.populateFields()

    if (this.editPlan) {
      this.loadSubscriptionActions()
      this.loadSubscriptions()
    }
  }

  populateFields () {
    if (this.editPlan) {
      this.sku = this.editPlan.sku
      this.meteredEntity = this.editPlan.metered_entity
      this.pupilsLicensed = this.editPlan.pupils !== null
      this.teachersLicensed = this.editPlan.teachers !== null
      this.classesLicensed = this.editPlan.classes !== null
      this.pupilUnitQuantity = this.editPlan.pupils
      this.teacherUnitQuantity = this.editPlan.teachers
      this.classUnitQuantity = this.editPlan.classes
      this.priceType = this.editPlan.price_type
      this.unitAmount = this.editPlan.unit_amount ?? '0.00'
      this.min = this.editPlan.min
      this.max = this.editPlan.max
      this.priceTiers = this.editPlan.price_tiers ?? [{
        up_to: null,
        flat_amount: '0.00',
        unit_amount: '0.00'
      }]
      this.addOnPrices = this.editPlan.children.map(c => ({
        id: c.id,
        price_type: c.price_type,
        price_tiers: c.price_tiers ?? [{
          up_to: null,
          flat_amount: '0.00',
          unit_amount: '0.00'
        }],
        unit_amount: c.unit_amount ?? '0.00',
        bundles_with: c.bundle_parents.map(p => ({ ...p, display: `${p.product_name} - ${p.scope_name}` })),
        add_on_id: c.add_on_id
      }))
      this.addOnPricesOriginal = _.cloneDeep(this.addOnPrices)
      this.pupilPermissions = this.editPlan.permissions.pupil_permissions
      this.teacherPermissions = this.editPlan.permissions.teacher_permissions
      this.classPermissions = this.editPlan.permissions.class_permissions
      this.schoolPermissions = this.editPlan.permissions.school_permissions
    } else if (this.versionOfPlan) {
      this.sku = this.skuGuess
      this.meteredEntity = this.versionOfPlan.metered_entity
      this.pupilsLicensed = this.versionOfPlan.pupils !== null
      this.teachersLicensed = this.versionOfPlan.teachers !== null
      this.classesLicensed = this.versionOfPlan.classes !== null
      this.pupilUnitQuantity = this.versionOfPlan.pupils
      this.teacherUnitQuantity = this.versionOfPlan.teachers
      this.classUnitQuantity = this.versionOfPlan.classes
      this.priceType = this.versionOfPlan.price_type
      this.unitAmount = this.versionOfPlan.unit_amount ?? '0.00'
      this.min = this.versionOfPlan.min
      this.max = this.versionOfPlan.max
      this.priceTiers = this.versionOfPlan.price_tiers ?? [{
        up_to: null,
        flat_amount: '0.00',
        unit_amount: '0.00'
      }]
      this.addOnPrices = this.versionOfPlan.children.map(c => ({
        id: null,
        price_type: c.price_type,
        price_tiers: c.price_tiers ?? [{
          up_to: null,
          flat_amount: '0.00',
          unit_amount: '0.00'
        }],
        unit_amount: c.unit_amount ?? '0.00',
        bundles_with: c.bundle_parents.map(p => ({ ...p, display: `${p.product_name} - ${p.scope_name}` })),
        add_on_id: c.add_on_id
      }))
      this.addOnPricesOriginal = _.cloneDeep(this.addOnPrices)
      this.pupilPermissions = this.versionOfPlan.permissions.pupil_permissions
      this.teacherPermissions = this.versionOfPlan.permissions.teacher_permissions
      this.classPermissions = this.versionOfPlan.permissions.class_permissions
      this.schoolPermissions = this.versionOfPlan.permissions.school_permissions
    } else {
      this.sku = this.skuGuess
    }
  }

  tabSelected (index: number) {
    if (index === this.addOnPrices.length + 1) {
      this.createNewAddOnPrice()
      this.selectedTab = this.addOnPrices.length
      return false
    }
  }

  createNewAddOnPrice () {
    this.addOnPrices.splice(this.addOnPrices.length, 0, { id: null, add_on_id: null, price_type: this.priceType, unit_amount: this.unitAmount, price_tiers: _.cloneDeep(this.priceTiers), bundles_with: [] })
    return true
  }

  confirmDeleteAddOn (index: number) {
    this.$buefy.dialog.confirm({
      message: 'You are about to delete an add-on. You cannot undo this action, unless you manually recreate the add-on details.',
      title: 'Confirm deletion',
      type: 'is-danger',
      confirmText: 'Proceed',
      hasIcon: true,
      onConfirm: () => {
        this.removeAddOn(index)
      }
    })
  }

  removeAddOn (index: number) {
    this.addOnPrices.splice(index, 1)
    this.selectedTab--
  }

  minToggled (value: boolean) {
    if (!value) {
      this.min = null
    } else {
      this.min = 1
    }
  }

  maxToggled (value: boolean) {
    if (!value) {
      this.max = null
    } else {
      this.max = 1
    }
  }

  bundleFilterTyping (val: string) {
    this.bundleFilter = val
  }

  createNewVersionClicked () {
    this.$emit('create-new', { scope: this.scope, currency: this.currencyProp, domestic: this.domesticProp, cycle: this.cycleProp, plan: this.editPlan })
  }

  switchToPlan (plan: SubscriptionPlanInfo) {
    this.$emit('changed-editing-plan', plan)
  }

  getStripeUrlForPrice (priceId: string) {
    const test = this.$store.state.api_environment !== 'production' ? '/test' : ''
    return `https://dashboard.stripe.com${test}/prices/${priceId}`
  }

  migrationDetailsForJob (jobId: string) {
    const details = this.scheduledActions.find(a => a.db_data?.job_id === jobId)
    return details
  }

  startBuildingMigration (plan: SubscriptionPlanInfo) {
    this.planToMigrate = plan
  }

  startBuildingActivation (plan: SubscriptionPlanInfo) {
    this.planToActivate = plan
  }

  startBuildingDeprecation (plan: SubscriptionPlanInfo) {
    this.planToDeprecate = plan
  }

  closeMigrationModal () {
    this.planToMigrate = null
  }

  closeActivationModal () {
    this.planToActivate = null
  }

  closeDeprecationModal () {
    this.planToDeprecate = null
  }

  timelineActionClicked (action: SubscriptionActionInfo) {
    if (action.plan) {
      this.switchToPlan(action.plan)
    }
  }

  saveMigration () {
    (this.$refs['migration-modal'] as AddPlanMigration).saveMigration()
  }

  addUserKeyToPerms (arr: SubscriptionPermissionRule<UserPermissionKeys>[]) {
    this.$buefy.dialog.prompt({
      message: 'Add a permission Key',
      inputAttrs: {
        placeholder: 'e.g. spelling-resources',
        maxlength: 30
      },
      trapFocus: true,
      onConfirm: (value) => {
        if (UserPermissionKeys.includes(value as any)) {
          const key = value as UserPermissionKeys

          arr.push({
            name: key,
            paid: true,
            trial: true
          })
        }
      }
    })
    arr.push
  }

  addSchoolKeyToPerms (arr: SubscriptionPermissionRule<SchoolPermissionKeys>[]) {
    this.$buefy.dialog.prompt({
      message: 'Add a permission Key',
      inputAttrs: {
        placeholder: 'e.g. flashcard-tool',
        maxlength: 30
      },
      trapFocus: true,
      onConfirm: (value) => {
        if (SchoolPermissionKeys.includes(value as any)) {
          const key = value as SchoolPermissionKeys

          arr.push({
            name: key,
            paid: true,
            trial: true
          })
        }
      }
    })
    arr.push
  }

  removeKeyFromPerms<E extends UserPermissionKeys | SchoolPermissionKeys> (arr: E[], rule: E) {
    const index = arr.findIndex(r => r === rule)
    arr.splice(index, 1)
  }

  async loadBundleableComponents () {
    try {
      this.bundleablePlans = await Api.getSubscriptionPlans({ parent_id: null, currency: this.currencyProp, cycle: this.cycleProp, domestic: this.domesticProp }, undefined)
    } catch (err: unknown) {
      this.$buefy.toast.open({
        message: 'Could not load bundle plans',
        type: 'is-danger',
        position: 'is-bottom'
      })

      if (err instanceof Error) {
        console.log(err.message)
      }
    }
  }

  async loadSubscriptionActions () {
    try {
      if (!this.editPlan) {
        return
      }

      this.scheduledActions = await Api.getSubscriptionActions({ plan_id: this.editPlan.id })
      this.scheduledActions.push(...(await Api.getSubscriptionActions({ to_id: this.editPlan.id })))

      for (const addOn of this.addOnPricesOriginal.filter(a => a.id !== null)) {
        this.scheduledActions.push(...(await Api.getSubscriptionActions({ plan_id: addOn.id! })))
        this.scheduledActions.push(...(await Api.getSubscriptionActions({ to_id: addOn.id! })))
      }
    } catch (err: unknown) {
      this.$buefy.toast.open({
        message: 'Could not load scheduled actions',
        type: 'is-danger',
        position: 'is-bottom'
      })

      if (err instanceof Error) {
        console.log(err.message)
      }
    }
  }

  async loadSubscriptions () {
    try {
      if (!this.editPlan) {
        return
      }

      this.subscriptions = await Api.getSubsForPlan(this.editPlan.id, {
        take: this.subscriptionTable.perPage,
        dir: this.subscriptionTable.dir,
        sort: this.subscriptionTable.sort,
        skip: (this.subscriptionTable.page - 1) * this.subscriptionTable.perPage
      })
    } catch (err: unknown) {
      this.$buefy.toast.open({
        message: 'Could not load subscriptions',
        type: 'is-danger',
        position: 'is-bottom'
      })

      if (err instanceof Error) {
        console.log(err.message)
      }
    }
  }

  async loadAlternatePlans () {
    try {
      const plans = await Api.getSubscriptionPlans({ scope_id: this.scope.id, product_id: this.product.id, currency: this.currencyProp, domestic: this.domesticProp, cycle: this.cycleProp, parent_id: null }, undefined)

      this.alternatePlans = plans.filter(p => p.id !== this.editPlan?.id)
    } catch (err: unknown) {
      this.$buefy.toast.open({
        message: 'Could not load alternate plans',
        type: 'is-danger',
        position: 'is-bottom'
      })

      if (err instanceof Error) {
        console.log(err.message)
      }
    }
  }

  async switchToEditAddOn (id: number) {
    try {
      const plans = await Api.getSubscriptionPlans({
        id
      }, undefined)

      if (plans.length !== 1) {
        throw new Error('Did not find plan')
      }

      const plans2 = await Api.getSubscriptionPlans({
        id: plans[0].parent_id!
      }, undefined)

      if (plans2.length !== 1) {
        throw new Error('Did not find plan')
      }

      this.switchToPlan(plans2[0])
    } catch (err: unknown) {
      this.$buefy.toast.open({
        message: 'Could not load add on details',
        type: 'is-danger',
        position: 'is-bottom'
      })
    }
  }

  getRandomAlphaNumericString (length: number) {
    let s = ''

    for (let i = 0; i < length; i += 1) {
      const letterCode = (Math.random() * (26 * 2) + 10) | 0

      let offset = 48

      if (letterCode > 9) {
        offset = 55
      }

      if (letterCode > 35) {
        offset = 61
      }

      s += String.fromCharCode(letterCode + offset)
    }

    return s
  }

  async saveActivation () {
    try {
      if (!this.editPlan) {
        throw new Error('editPlan not set')
      }

      const action = await Api.addActionForPlan(this.editPlan!.id, {
        action: 'activate',
        date: this.activationType === 'later' ? this.activationDate : null,
        batch_id: null
      })

      this.scheduledActions.push(action)

      for (const child of this.editPlan.children) {
        const addOnAction = await Api.addActionForPlan(child.id, {
          action: 'activate',
          date: this.activationType === 'later' ? this.activationDate : null,
          batch_id: action.batch_id
        })

        this.scheduledActions.push(addOnAction)
      }

      this.planToActivate = null
    } catch (err: unknown) {
      console.log(err)

      this.$buefy.toast.open({
        message: 'Could not set activation date',
        position: 'is-bottom',
        type: 'is-danger'
      })
    }
  }

  async saveDeprecation () {
    try {
      if (!this.editPlan) {
        throw new Error('editPlan not set')
      }

      const action = await Api.addActionForPlan(this.editPlan!.id, {
        action: 'deprecate',
        date: this.deprecationType === 'later' ? this.deprecationDate : null,
        batch_id: null
      })

      this.scheduledActions.push(action)

      this.planToDeprecate = null
    } catch (err: unknown) {
      console.log(err)

      this.$buefy.toast.open({
        message: 'Could not set deprecation date',
        position: 'is-bottom',
        type: 'is-danger'
      })
    }
  }

  async savePlan () {
    this.savingPlans = true
    const that = this

    async function addChildPlan (parentId: number, child: AddOnDetails) {
      const ident = child.add_on_id ?? that.getRandomAlphaNumericString(6)

      const plan = await Api.addSubscriptionPlan(that.scope.id, {
        sku: `${that.sku}-addon-${ident}`,
        parent_id: parentId,
        price_type: child.price_type,
        unit_amount: child.price_type === 'fixed' ? child.unit_amount : null,
        price_tiers: child.price_type !== 'fixed' ? child.price_tiers : null,
        currency: that.currencyProp,
        account_region: (that.currencyProp === 'usd' && that.domesticProp === false) ? 'US' : 'GB',
        cycle: that.cycleProp,
        domestic: that.domesticProp,
        base: false,
        metered_entity: that.meteredEntity,
        teachers: null,
        pupils: null,
        classes: null,
        min: null,
        max: null,
        add_on_id: ident,
        bundles_with: child.bundles_with.map(b => b.scope_id),
        permissions: {
          pupil_permissions: that.pupilPermissions,
          teacher_permissions: that.teacherPermissions,
          class_permissions: that.classPermissions,
          school_permissions: that.schoolPermissions
        }
      })

      return plan
    }

    function savePlanError (message: string) {
      that.$buefy.toast.open({
        message,
        position: 'is-bottom',
        type: 'is-danger'
      })
    }

    try {
      if (this.sku.trim() === '') {
        return savePlanError('SKU required')
      }

      const updatedPlans: SubscriptionPlanInfo[] = []
      const createdPlans: SubscriptionPlanInfo[] = []

      if (this.editPlan) {
        const plan = await Api.updateSubscriptionPlan(this.editPlan.id, {
          metered_entity: this.meteredEntity,
          pupils: this.pupilUnitQuantity,
          teachers: this.teacherUnitQuantity,
          classes: this.classUnitQuantity,
          min: this.min,
          max: this.max,
          permissions: {
            pupil_permissions: this.pupilPermissions,
            teacher_permissions: this.teacherPermissions,
            class_permissions: this.classPermissions,
            school_permissions: this.schoolPermissions
          }
        })

        updatedPlans.push(plan)

        for (const child of this.addOnPrices) {
          if (child.id) {
            const childPlan = await Api.updateSubscriptionPlan(child.id, {
              bundles_with: child.bundles_with.map(b => b.scope_id),
              permissions: plan.permissions,
              metered_entity: plan.metered_entity
            })

            updatedPlans.push(childPlan)
          } else {
            const childPlan = await addChildPlan(this.editPlan.id, child)

            createdPlans.push(childPlan)
          }
        }
      } else {
        const plan = await Api.addSubscriptionPlan(this.scope.id, {
          sku: this.sku,
          parent_id: null,
          price_type: this.priceType,
          unit_amount: this.priceType === 'fixed' ? this.unitAmount : null,
          price_tiers: this.priceType !== 'fixed' ? this.priceTiers : null,
          currency: this.currencyProp,
          account_region: (this.currencyProp === 'usd' && this.domesticProp === false) ? 'US' : 'GB',
          cycle: this.cycleProp,
          domestic: this.domesticProp,
          base: true,
          metered_entity: this.meteredEntity,
          teachers: this.teacherUnitQuantity,
          pupils: this.pupilUnitQuantity,
          classes: this.classUnitQuantity,
          min: this.min,
          max: this.max,
          add_on_id: null,
          bundles_with: [],
          permissions: {
            pupil_permissions: this.pupilPermissions,
            teacher_permissions: this.teacherPermissions,
            class_permissions: this.classPermissions,
            school_permissions: this.schoolPermissions
          }
        })

        createdPlans.push(plan)
        const createdChildren: SubscriptionPlanInfo[] = []

        for (const child of this.addOnPrices) {
          const childProduct = await addChildPlan(plan.id, child)

          createdPlans.push(childProduct)
          createdChildren.push(childProduct)
        }

        if (this.activationType !== 'legacy') {
          const action = await Api.addActionForPlan(plan.id, {
            action: 'activate',
            date: this.activationType === 'later' ? this.activationDate : null,
            batch_id: null
          })

          for (const child of createdChildren) {
            await Api.addActionForPlan(child.id, {
              action: 'activate',
              date: this.activationType === 'later' ? this.activationDate : null,
              batch_id: action.batch_id
            })
          }
        }
      }

      for (const plan of createdPlans) {
        this.$emit('created', plan)
      }

      for (const plan of updatedPlans) {
        this.$emit('updated', plan)
      }
    } catch (err) {
      console.log(err)

      this.$buefy.toast.open({
        message: 'Could not save data',
        position: 'is-bottom',
        type: 'is-danger'
      })
    } finally {
      this.savingPlans = false
    }
  }

  migrationAdded (action: SubscriptionActionInfo) {
    this.scheduledActions.push(action)
    this.closeMigrationModal()
  }
}
