




import ComponentHelper from '@/mixins/ComponentHelper'
import Vue from 'vue'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

@Component({
  name: 'CurrencyInput'
})
export default class CurrencyInput extends Mixins(ComponentHelper) {
  @Prop({ default: '0.00' })
  value!: string

  @Prop({ default: undefined })
  size!: string

  @Prop({ default: false })
  disabled!: boolean

  inputValue = ''

  handleChange () {
    this.$emit('input', this.inputValue)
  }

  @Watch('value')
  valuePropChanged (val: string) {
    this.inputValue = val
  }

  mounted () {
    this.inputValue = this.value
    const input = ((this.$refs.input as Vue).$refs.input as HTMLInputElement)

    input.addEventListener('keydown', (event) => {
      if (!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Delete', 'Backspace', '.', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Tab'].includes(event.key)) {
        event.preventDefault()
        return true
      }

      if (event.key === '.') {
        const position = input.selectionStart
        const existingDotIndex = this.inputValue.indexOf('.')

        if (position === null) {
          event.preventDefault()
          return true
        }

        if (existingDotIndex === position) {
          // at the decimal - skip over it
          input.setSelectionRange(position + 1, position + 1)
          event.preventDefault()
          return true
        } else if (existingDotIndex < position) {
          // after the decimal - not allowed
          event.preventDefault()
          return true
        } else {
          // before the decimal - re-align decimals to current position
          this.inputValue = this.inputValue.replace('.', '')
          this.inputValue = [this.inputValue.slice(0, position), '.', this.inputValue.slice(position)].join('')

          if (this.requiresDecimals()) {
            this.inputValue = parseFloat(this.inputValue).toFixed(2)
          }

          this.handleChange()

          this.$nextTick(() => {
            input.setSelectionRange(position + 1, position + 1)
          })

          event.preventDefault()
          return true
        }
      }

      if (event.key === 'Backspace') {
        const position = input.selectionStart
        const existingDotIndex = this.inputValue.indexOf('.')

        if (position === null) {
          event.preventDefault()
          return true
        }

        if (position - existingDotIndex === 1) {
          // just after the decimal - delete first character before decimal and position caret there
          this.inputValue = [this.inputValue.slice(0, position - 2), this.inputValue.slice(position - 1)].join('')
          this.$nextTick(() => {
            input.setSelectionRange(position - 2, position - 2)
          })
          this.handleChange()

          event.preventDefault()
          return true
        } else {
          if (this.requiresDecimals()) {
            this.inputValue = parseFloat(this.inputValue).toFixed(2)
            this.handleChange()
          }
        }
      }

      if (event.key === 'Delete') {
        const position = input.selectionStart
        const existingDotIndex = this.inputValue.indexOf('.')

        if (position === null) {
          event.preventDefault()
          return true
        }

        if (existingDotIndex - position === 0) {
          // at the decimal - delete first character after decimal and position caret there
          this.inputValue = [this.inputValue.slice(0, position + 1), this.inputValue.slice(position + 2)].join('')
          this.$nextTick(() => {
            input.setSelectionRange(position + 1, position + 1)
          })

          if (this.requiresDecimals()) {
            this.inputValue = parseFloat(this.inputValue).toFixed(2)
          }

          this.handleChange()

          event.preventDefault()
          return true
        } else {

        }
      }

      const position = input.selectionStart ?? 0

      this.$nextTick(() => {
        input.setSelectionRange(position, position)
      })
    })
  }

  parseInput (val: string) {
    const input = ((this.$refs.input as Vue).$refs.input as HTMLInputElement)
    const position = input.selectionStart ?? 0
    this.inputValue = val
    this.handleChange()

    if (this.requiresDecimals()) {
      this.$nextTick(() => {
        this.inputValue = parseFloat(this.inputValue).toFixed(2)
        this.handleChange()
        this.$nextTick(() => {
          input.setSelectionRange(position, position)
        })
      })
    }

    this.$nextTick(() => {
      input.setSelectionRange(position, position)
    })
  }

  requiresDecimals () {
    const split = this.inputValue.split('.')

    let needsDecimals = false

    if (split.length === 1) {
      needsDecimals = true
    } else {
      const postDecimals = split[1]

      if (postDecimals.replace(/0*$/, '').length < 3) {
        needsDecimals = true
      }
    }

    return needsDecimals
  }
}
